import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';

import useSiteMetadata from '../components/SiteMetadata';

import ContextProvider from '../provider/ContextProvider';
import PlayerContext from '../context/PlayerContext';
import ThemeContext from '../context/ThemeContext';

import Player from '../components/Player/Player';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import MiniNav from '../components/MiniNav';
import Loading from '../components/Loading';

import '../components/normalize.css';

const Container = styled.div((props) => ({
  background: props.theme.color.background[30],
  color: props.theme.color.foreground[100],
  transition: 'color 450ms ease, background 1200ms ease',
  fontFamily: "'AcherusGrotesque', serif",
  overflowY: 'hidden',
  minHeight: '100vh',
}));

const PlayerContainer = styled.div(
  (props) => `
  position: relative;
  z-index: 700;
  display: flex;
  flex-direction: column;
  &.fixed {
    height: calc(100vh);
    position: fixed;
    z-index: 700;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
  }
  ${
    props.breakpoints.md &&
    `
    &, &.fixed {
      position: fixed;
      z-index: 700;
      top: 0;
      left: 0;
      width: ${props.theme.size.base * 35}px;
      height: 100vh;
      overflow: hidden;
    }
  `
  };
  ${
    props.breakpoints.lg &&
    `
    &, &.fixed {
      position: fixed;
      z-index: 700;
      overflow: hidden;
      top: ${props.theme.size.base * 2}px;
      left: ${props.theme.size.base * 2}px;
      width: calc(${props.theme.size.base * 39}px - ${
      props.theme.size.base * 4
    }px);
      height: calc(100vh - ${props.theme.size.base * 4}px);
      border-radius:  ${props.theme.size.base * 3}px;
    }
  `
  };
  ${
    props.breakpoints.xl &&
    `
    &, &.fixed {
      position: fixed;
      z-index: 700;
      top: ${props.theme.size.base * 4}px;
      left: ${props.theme.size.base * 4}px;
      width: calc(${props.theme.size.base * 60}px - ${
      props.theme.size.base * 8
    }px);
      height: calc(100vh - ${props.theme.size.base * 8}px);
      overflow: hidden;
      border-radius:  ${props.theme.size.base * 4}px;
    }
  `
  };
  `
);

const NavContainer = styled.div(
  (props) => `
  background: ${props.theme.color.background[65]};
  background: linear-gradient(
    30deg,
    ${props.theme.color.background[70]},
    ${props.theme.color.background[60]}
  );
  position: fixed;
  z-index: 800;
  top: 0;
  left: 0;
  right: 0;
  ${
    (props.breakpoints.xs || props.breakpoints.sm) &&
    `
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${props.theme.color.secondary[40]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.secondary[35]},
        ${props.theme.color.tertiary[50]}
      );
      border: 0;
    }
  `
  };
  ${
    props.breakpoints.md &&
    `
    margin-left: ${props.theme.size.base * 35}px;
  `
  };
  ${
    props.breakpoints.lg &&
    `
    margin-left: ${props.theme.size.base * 39}px;
    margin-bottom: ${props.theme.size.base * 2}px;
    border-radius: 0 0 0 ${props.theme.size.base * 3}px;
  `
  };
  ${
    props.breakpoints.xl &&
    `
    margin-left: ${props.theme.size.base * 60}px;
    margin-bottom: ${props.theme.size.base * 4}px;
    border-radius: 0 0 0 ${props.theme.size.base * 4}px;
  `
  };
  `
);

const ContentContainer = styled.div(
  (props) => `
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - ${props.theme.size.base * 33}px);
  ${
    props.breakpoints.md &&
    `
    min-height: calc(100vh - ${props.theme.size.base * 17}px);
    margin-left: ${props.theme.size.base * 35}px;
    padding-top: ${props.theme.size.base * 8}px;
  `
  };
  ${
    props.breakpoints.lg &&
    `
    min-height: calc(100vh - ${props.theme.size.base * 17}px);
    margin-left: ${props.theme.size.base * 39}px;
    padding-top: ${props.theme.size.base * 11}px;
  `
  };
  ${
    props.breakpoints.xl &&
    `
    min-height: calc(100vh - ${props.theme.size.base * 17}px);
    margin-left: ${props.theme.size.base * 60}px;
    padding-top: ${props.theme.size.base * 13}px;
  `
  };
  `
);
const FooterContainer = styled.div(
  (props) => `
  ${
    props.breakpoints.md &&
    `
    margin-left: ${props.theme.size.base * 35}px;
  `
  };
  ${
    props.breakpoints.lg &&
    `
    margin-left: ${props.theme.size.base * 39}px;
  `
  };
  ${
    props.breakpoints.xl &&
    `
    margin-left: ${props.theme.size.base * 60}px;
  `
  };
  `
);

const Typesetter = styled.div(
  (props) => `
  .typesetter {
    color: ${props.theme.color.foreground[70]};
    transition: color 450ms ease;
    font-size: ${props.theme.size.fontSize[3]};
    line-height: ${props.theme.size.lineHeight[3]};
    p {
      font-size: ${props.theme.size.fontSize[3]};
      line-height: ${props.theme.size.lineHeight[3]};
      margin: 0 0 ${props.theme.size.base * 2}px;
      padding: 0;
    }
    h1 {
      font-weight: 700;
      letter-spacing: 0.03em;
      font-size: ${
        props.breakpoints.lg || props.breakpoints.xl
          ? props.theme.size.fontSize[10]
          : props.theme.size.fontSize[8]
      };
      line-height: ${
        props.breakpoints.lg || props.breakpoints.xl
          ? props.theme.size.lineHeight[10]
          : props.theme.size.lineHeight[8]
      };
      text-decoration: none;
      color: ${props.theme.color.foreground[100]};
      transition: color 450ms ease;
      margin: 0 0 ${props.theme.size.base * 4}px;
      padding: 0;
    }
    h2 {
      color: ${
        props.theme.dark
          ? props.theme.color.secondary[70]
          : props.theme.color.secondary[50]
      };
      transition: color 450ms ease;
      font-size: ${
        props.breakpoints.lg || props.breakpoints.xl
          ? props.theme.size.fontSize[8]
          : props.theme.size.fontSize[5]
      };
      line-height: ${
        props.breakpoints.lg || props.breakpoints.xl
          ? props.theme.size.lineHeight[8]
          : props.theme.size.lineHeight[5]
      };
      margin: 0 0 ${props.theme.size.base * 2}px;
      padding: 0;
      font-weight: 300;
    }
    h3 {
      color: ${
        props.theme.dark
          ? props.theme.color.tertiary[70]
          : props.theme.color.tertiary[50]
      };
      transition: color 450ms ease;
      font-size: ${props.theme.size.fontSize[4]};
      line-height: ${props.theme.size.lineHeight[4]};
      margin: 0 0 ${props.theme.size.base * 2}px;
      padding: 0;
      font-weight: 600;
      letter-spacing: 0.1em;
    }
    h4 {
      color: ${
        props.theme.dark
          ? props.theme.color.tertiary[70]
          : props.theme.color.tertiary[50]
      };
      transition: color 450ms ease;
      font-size: ${props.theme.size.fontSize[3]};
      line-height: ${props.theme.size.lineHeight[3]};
      margin: 0 0 ${props.theme.size.base * 2}px;
      padding: 0;
      font-weight: 600;
      letter-spacing: 0.1em;
    }
    a,
    a:visited {
      color: ${
        props.theme.dark
          ? props.theme.color.primary[60]
          : props.theme.color.primary[50]
      };
      transition: color 450ms ease;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.secondary[70]
            : props.theme.color.secondary[60]
        };
      }
    }
    blockquote {
      position: relative;
      margin: 0 0 ${props.theme.size.base * 4}px;
      padding: ${props.theme.size.base * 4}px;
      background: ${props.theme.color.background[65]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.background[70]},
        ${props.theme.color.background[60]}
      );
      p:last-of-type {
        margin-bottom: 0;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: ${props.theme.size.base * 0.5}px;
        background: ${props.theme.color.primary[55]};
        background: linear-gradient(
          30deg,
          ${props.theme.color.primary[50]},
          ${props.theme.color.secondary[60]}
        );
      }
    }
    pre {
      margin: 0 0 ${props.theme.size.base * 4}px;
    }
    .lead {
      font-size: ${props.theme.size.fontSize[5]};
      line-height: ${props.theme.size.lineHeight[5]};
      color: ${
        props.theme.dark
          ? props.theme.color.primary[80]
          : props.theme.color.primary[60]
      };
      transition: color 450ms ease;
      font-weight: 300;
    }
    img {
      max-width: 100%;
    }
  }
  .section {
    width: 100%;
    min-height: calc(100vh -  ${props.theme.size.base * 15}px);
  }
  .container {
    padding: ${props.theme.size.base * 13}px 
      ${props.theme.size.base * 4}px 
      ${props.theme.size.base * 4}px;
  }
  .content {
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .callout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${props.theme.size.base * 10}px 
      ${props.theme.size.base * 12}px 
      ${props.theme.size.base * 12}px;
  }

  .field {
    padding: ${props.theme.size.base}px 0;
  }
  .label {
    color: ${
      props.theme.dark
        ? props.theme.color.primary[60]
        : props.theme.color.foreground[50]
    };
    transition: color 450ms ease;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    padding-bottom: ${props.theme.size.base}px;
    font-weight: 400;
    letter-spacing: 0.1em;
  }
  .control {
  }
  .textarea,
  .input {
    width: calc(100% - ${props.theme.size.base * 2}px);
    max-width: calc(100% - ${props.theme.size.base * 2}px);
    border: 2px solid ${props.theme.color.secondary[50]};
    border-radius: ${props.theme.size.base}px;
    color: ${props.theme.color.foreground[100]};
    transition: color 450ms ease, border-color 450ms ease;
    background: transparent;
    padding: ${props.theme.size.base}px;
    font-size: ${props.theme.size.fontSize[3]};
    line-height: ${props.theme.size.lineHeight[3]};
    font-family: 'AcherusGrotesque', serif;
  }
  .textarea {
    max-height: ${props.theme.size.base * 48}px;
  }
  .nowrap {
    white-space: nowrap;
  }
  .scroll-x {
    max-height: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.4);
      background: rgba(255, 255, 255, 0.4);
      background: ${props.theme.color.secondary[40]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.secondary[35]},
        ${props.theme.color.tertiary[50]}
        );
        border: 0;
    }
  }
  .scroll-y {
    max-width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.4);
      background: rgba(255, 255, 255, 0.4);
      background: ${props.theme.color.secondary[40]};
      background: linear-gradient(
        30deg,
        ${props.theme.color.secondary[35]},
        ${props.theme.color.tertiary[50]}
        );
        border: 0;
    }
  }
  .align-right,
  th.align-right {
    text-align: right;
  }
  .align-left,
  th.align-left {
    text-align: left;
  }
  .align-center,
  th.align-center {
    text-align: center;
  }
  .data-table {
    border-spacing: 0;
    .number {
      text-align: right;
      color: ${props.theme.color.secondary[55]};
      transition: color 450ms ease;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.2em;
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
    }
    th {
      color: ${props.theme.color.accent[55]};
      transition: color 450ms ease, border-color 450ms ease;
      padding: ${props.theme.size.base * 2}px ${props.theme.size.base}px;
      border-bottom: 1px solid ${props.theme.color.secondary[50]};
      font-weight: 400;
      letter-spacing: 0.1em;
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
      text-align: left;
      &:first-of-type {
        padding-left: ${props.theme.size.base * 2}px;
      }
      &:last-of-type {
        padding-right: ${props.theme.size.base * 2}px;
      }
    }
    td {
      padding: ${props.theme.size.base * 2}px ${props.theme.size.base}px;
      border-bottom: 1px solid ${props.theme.color.secondary[30]};
      background: transparent;
      transition: background 450ms ease, border-color 450ms ease;
      &:first-of-type {
        padding-left: ${props.theme.size.base * 2}px;
      }
      &:last-of-type {
        padding-right: ${props.theme.size.base * 2}px;
      }
      min-width:  ${props.theme.size.base * 20}px;
      &.shrink {
        min-width: 0;
      }
    }
  }

  .__json-pretty__  {
    line-height: ${props.theme.size.lineHeight[1]};
    font-size: ${props.theme.size.fontSize[1]};
    color: ${props.theme.color.foreground[100]};
    background: ${props.theme.color.background[90]};
    overflow: auto;
  }

  .__json-key__ {
    color: ${props.theme.color.secondary[65]};
  }
  .__json-value__ {
    color: ${props.theme.color.primary[55]};
  }
  .__json-string__ {
    color: ${props.theme.color.tertiary[85]};
  }
  .__json-boolean__ {
    color: ${props.theme.color.accent[55]};
  }
  .__json-pretty-error__ {
    line-height: ${props.theme.size.lineHeight[1]};
    font-size: ${props.theme.size.fontSize[1]};
    color: ${props.theme.color.foreground[100]};
    color: ${props.theme.color.accent[25]};
    overflow: auto;
  }  
`
);

const Layout = ({ location, children }) => {
  const { title, description } = useSiteMetadata();
  const breakpoints = useBreakpoint();

  return (
      <ContextProvider>
        <PlayerContext.Consumer>
          {(context) => (
            <ThemeContext.Consumer>
              {(theme) => (
                <TransitionProvider
                  location={location}
                  enter={{
                    opacity: 0,
                    config: {
                      mass: 1,
                      tension: 210,
                      friction: 20,
                      clamp: true,
                    },
                  }}
                  usual={{
                    opacity: 1,
                  }}
                  leave={{
                    opacity: 0,
                    config: {
                      mass: 1,
                      tension: 210,
                      friction: 20,
                      clamp: true,
                    },
                  }}
                >
                  <Container theme={theme}>
                    <Helmet>
                      <html lang='en' />
                      <title>{title}</title>
                      <meta name='description' content={description} />

                      <link
                        rel='apple-touch-icon'
                        sizes='180x180'
                        href={`${withPrefix('/')}img/apple-touch-icon.png`}
                      />
                      <link
                        rel='icon'
                        type='image/png'
                        href={`${withPrefix('/')}img/favicon-32x32.png`}
                        sizes='32x32'
                      />
                      <link
                        rel='icon'
                        type='image/png'
                        href={`${withPrefix('/')}img/favicon-16x16.png`}
                        sizes='16x16'
                      />

                      <link
                        rel='mask-icon'
                        href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                        color='#ff4400'
                      />
                      <meta
                        name='theme-color'
                        content={theme.color.background[50]}
                        data-react-helmet='true'
                      />

                      <meta property='og:type' content='business.business' />
                      <meta property='og:title' content={title} />
                      <meta property='og:url' content='/' />
                      <meta
                        property='og:image'
                        content={`${withPrefix('/')}img/og-image.jpg`}
                      />
                    </Helmet>
                    <Loading theme={theme} breakpoints={breakpoints} />

                    <PlayerContainer
                      theme={theme}
                      breakpoints={breakpoints}
                      fullscreen={context.fullscreen}
                      className={
                        location.pathname !== '/' && !context.miniPlayer
                          ? 'fixed'
                          : null
                      }
                    >
                      <Player path={location.pathname} />
                    </PlayerContainer>
                    {(breakpoints.md ||
                      breakpoints.lg ||
                      breakpoints.xl ||
                      !context.miniNav) && (
                      <NavContainer theme={theme} breakpoints={breakpoints}>
                        <Navbar location={location} />
                      </NavContainer>
                    )}

                    {(breakpoints.xs || breakpoints.sm) && (
                      <MiniNav theme={theme}></MiniNav>
                    )}

                    <ContentContainer theme={theme} breakpoints={breakpoints}>
                      <Typesetter theme={theme} breakpoints={breakpoints}>
                        <TransitionViews>{children}</TransitionViews>
                      </Typesetter>
                    </ContentContainer>

                    <FooterContainer theme={theme} breakpoints={breakpoints}>
                      <Footer />
                    </FooterContainer>
                  </Container>
                </TransitionProvider>
              )}
            </ThemeContext.Consumer>
          )}
        </PlayerContext.Consumer>
      </ContextProvider>
  );
};

export default Layout;
